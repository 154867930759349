import axios from 'axios'

export const get = (id, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/lot/' + id)
         .then(then)
}

export const create = (data, then, fail) => {
    axios.post(process.env.REACT_APP_API_URL + '/lots/create', data)
        .then(then).catch(error => {
            if (fail) {
                fail(error.response)
            }
        })
}

export const update = (id, data, then, fail) => {
    axios.post(process.env.REACT_APP_API_URL + '/lot/' + id + '/update', data)
        .then(then).catch(error => {
            if (fail) {
                fail(error.response)
            }
        })
}

export const updateConfig = (id, data, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/lot/' + id + '/config', data)
         .then(then)
}

export const approve = (id, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/lot/' + id + '/approve')
         .then(then)
}

export const reject = (id, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/lot/' + id + '/reject')
         .then(then)
}

export const recover = (id, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/lot/' + id + '/recover')
         .then(then)
}

export const approvals = (id, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/lot/' + id + '/approvals')
         .then(then)
}

export const approveChanges = (id, then, data = null) => {
    axios.post(process.env.REACT_APP_API_URL + '/lot/' + id + '/approve-changes', data)
         .then(then)
}

export const rejectChanges = (id, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/lot/' + id + '/reject-changes')
         .then(then)
}

export const deleteLot = (id, then) => {
    axios.delete(process.env.REACT_APP_API_URL + '/lot/' + id + '/delete')
        .then(then)
}

export const photos = (id, params, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/lot/' + id + '/photos', {
        params
    }).then(then)
}

export const rotatePhoto = (id, asset_id, params, then, approval = false) => {
    axios.post(process.env.REACT_APP_API_URL + '/lot/' + id + (approval ? '/approval' : '') + '/photos/' + asset_id + '/rotate', params)
         .then(then)
}

export const deleteAllPhotos = (id, then, approval = false) => {
    axios.delete(process.env.REACT_APP_API_URL + '/lot/' + id + (approval ? '/approval' : '') + '/photos/deleteAll')
         .then(then)
}

export const bids = (id, params, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/lot/' + id + '/bids', {
        params
    }).then(then)
}

export const watches = (id, params, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/lot/' + id + '/watches', {
        params
    }).then(then)
}

export const placeBid = (lot_id, data, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/lot/' + lot_id + '/bid', data)
         .then(then)
}

export const updateBid = (lot_id, bid_id, data, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/lot/' + lot_id + '/bids/' + bid_id + '/update', data)
         .then(then)
}

export const revertToBid = (lot_id, bid_id, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/lot/' + lot_id + '/bids/' + bid_id + '/revert-to')
         .then(then)
}

export const revertAllBids = (id, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/lot/' + id + '/bids/revert-all')
         .then(then)
}

export const returnToInventory = (data, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/lots/return-to-inventory', data).then(then)
}

export const transfer = (data, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/lots/transfer', data).then(then)
}

export const stagger = (auction_id, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/lots/stagger/' + auction_id).then(then)
        .catch(response => {
            alert("Failed to stagger lots.");
        })
}

export const staggerCheck = (auction_id, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/lots/stagger/' + auction_id + '/check')
        .then(then)
}

export const listModified = (lot) => {
    const modified = []

    lot.pending_approvals.forEach(approval => {
        listApprovalModified(lot, approval, modified)
    })

    return modified
}

export const listApprovalModified = (lot, approval, modified) => {
    const excluded = ['editable', 'isWatched', 'paid', 'max_bid', 'user_min_bid', 'bidder_status', 'created_at', 'updated_at']

    Object.entries(approval.data).forEach(([k, v]) => {
        if (typeof v === 'object' && v !== null) {
            if (k === 'item') {
                Object.entries(v).forEach(([k, v]) => {
                    if (typeof v === 'object' && typeof v !== null) {
                        return
                    }

                    if (excluded.indexOf(k) >= 0) {
                        return
                    }

                    if (lot.item[k] != v) {
                        modified.push({
                            k,
                            v,
                            item: true
                        })
                    }
                })
            }

            return
        }

        if (excluded.indexOf(k) >= 0) {
            return
        }

        if (lot[k] != v) {
            modified.push({
                k,
                v
            })
        }
    })
}

export const massUpdate = (auction_id, data, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/auction/' + auction_id + '/lots/mass-update', data).then(then)
        .catch(response => {
            alert("Failed to update lots.");
        })
}

export const setLots = (auction_id, data, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/auction/' + auction_id + '/lots/set', data).then(then)
        .catch(response => {
            alert("Failed to update lots.");
        })
}