import { createContext, useContext, useEffect, useReducer } from 'react'
import {
    getPermissions,
    getUnreadNotifications,
    markNotificationRead as postMarkNotificationRead,
    markAllNotificationsRead as postMarkAllNotificationsRead
} from 'services/auth'
import useEcho from 'hooks/Echo'
import { useSelector } from 'react-redux'
import { logoutSuccess } from 'store/user'

export const AuthContext = createContext(null)
export const AuthDispatchContext = createContext(null)

export const AuthProvider = ({ initial = {}, children }) => {
    const [ auth, dispatch ] = useReducer(authReducer, initial)

    const echo = useEcho()
    const { user } = useSelector(state => state.user)

    useEffect(() => {
        updatePermissions(dispatch)
        updateNotifications(dispatch)

        const intervalId = setInterval(
            () => {
                updateNotifications(dispatch)
            }
        , 60000)

        return () => {
            clearInterval(intervalId)
        }
    }, [])

    useEffect(() => {
        if (echo && user) {
            echo.private(`App.Models.User.${user.id}`).notification((notification) => {
                dispatch({
                    type: 'appendNotification',
                    notification
                })
            });

            echo.private(`users.${user.id}`).listen("Logout", (e) => {
                console.log('handling Logout event');

                logoutSuccess();

                setTimeout(() => {
                    window.location.reload();
                }, 1000);

                dispatch({
                    type: 'logout'
                });
            });
        }

        return () => {
            if (echo && user) {
                echo.leaveChannel(`App.Models.User.${user.id}`);
                echo.private(`users.${user.id}`)
                    .stopListening('Logout');
            }
        }
    }, [echo, user])

    return (
        <AuthContext.Provider value={auth}>
            <AuthDispatchContext.Provider value={dispatch}>
                {children}
            </AuthDispatchContext.Provider>
        </AuthContext.Provider>
    )
}

export const updatePermissions = (dispatch, then) => {
    getPermissions({}, ({ data }) => {
        if (then) {
            then()
        }

        dispatch({
            type: 'update',
            permissions: data
        })
    })
}

export const updateNotifications = (dispatch, then) => {
    getUnreadNotifications({}, ({ data }) => {
        if (then) {
            then()
        }

        dispatch({
            type: 'updateNotifications',
            notifications: data
        })
    })
}

export const markNotificationRead = (dispatch, id, then) => {
    postMarkNotificationRead(id, {}, ({ data }) => {
        if (then) {
            then()
        }

        dispatch({
            type: 'updateNotifications',
            notifications: data
        })
    })
}

export const markAllNotificationsRead = (dispatch, then) => {
    postMarkAllNotificationsRead({}, ({ data }) => {
        if (then) {
            then()
        }

        dispatch({
            type: 'updateNotifications',
            notifications: data
        })
    })
}

export function useAuth() {
    return useContext(AuthContext);
}

export function useAuthDispatch() {
    return useContext(AuthDispatchContext);
}

export function can(auth, permission) {
    if (auth.permissions?.scoped) {
        let scopedMatch = false

        auth.permissions?.scoped.forEach((scope) => {
            scopedMatch = scope.permissions[permission]
        })

        if (scopedMatch) {
            return true
        }
    }

    return auth.permissions?.global[permission]
}

const authReducer = (auth, action) => {
    switch (action.type) {
        case 'update': {
            return {
                ...auth,
                permissions: action.permissions
            };
        }

        case 'logout': {
            return {
                ...auth,
                permissions: {
                    admin: {},
                    global: {},
                    scoped: [],
                    limits: {}
                }
            };
        }

        case 'updateNotifications': {
            return {
                ...auth,
                notifications: action.notifications
            }
        }

        case 'appendNotification': {
            return {
                ...auth,
                notifications: {
                    ...auth.notifications,
                    total: auth.notifications.total + 1,
                    data: [
                        ...auth.notifications.data,
                        action.notification
                    ]
                },
            }
        }

        case 'setActiveAuction': {
            return {
                ...auth,
                active_auction: action.url
            }
        }

        default: {
            throw Error('Unknown action: ' + action.type);
        }
    }
}
