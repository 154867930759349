const CheckboxField = ({ label, checked, onChange, title = '' }) => {
    return (
        <label className="flex gap-2 items-center" title={title}>
            <input
                type="checkbox"
                checked={checked}
                onChange={onChange}
            />
            <span>{label}</span>
        </label>
    )
}

export default CheckboxField