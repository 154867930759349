import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import AuctionLayout from './components/AuctionLayout'
import { get } from 'services/auctions'
import AssetGallery from 'components/assets/AssetGallery'

const AuctionDocuments = () => {
    const entityType = 'App\\Models\\Auction'
    const { id } = useParams()
    const [ auction, setAuction ] = useState(null)

    useEffect(() => {
        loadAuction()
    }, [id])

    const loadAuction = () => {
        if (! id) {
            return
        }

        get(id, ({ data }) => {
            setAuction(data.auction)
        }, {
            minimal: true,
            related: [
                'documents'
            ]
        })
    }

    if (! auction) {
        return
    }

    return (
        <AuctionLayout id={id} auction={auction} setAuction={setAuction}>
            <div className="mt-3">
                <p className="mb-4">
                    Auction documents, such as flyers and legal documents, can be stored here so that the bidders are
                    able to access them.
                </p>

                <AssetGallery
                    entityId={id}
                    entityType={entityType}
                    assets={auction.documents}
                    type="document"
                />
            </div>
        </AuctionLayout>
    )
}

export default AuctionDocuments