import React, { useState } from "react";
import InputField from "components/fields/InputField";
import Default from "layouts/auth/types/Default";
import Checkbox from "components/checkbox";
import { useDispatch } from 'react-redux'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { login } from 'store/user'
import logo from 'assets/img/peak/logo.svg'

function SignInDefault() {
  const [ data, setData ] = useState({
    admin: 1
  })
  const [ error, setError ] = useState(false)
  const [ searchParams ] = useSearchParams()
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const recentlyResetPassword = searchParams.has('reset')

  const handleChange = (e) => {
    data[e.target.name] = e.target.value

    setData({
      ...data
    })
  }

  const submit = (e) => {
    e.preventDefault()

    dispatch(login(data, () => {
      navigate('/admin/dashboard')
    }, (error) => {
      setError(error.response.data.message)
    }))
  }

  return (
    <Default
      maincard={
        <div className="mb-8 md:mt-16 md:mb-16 flex h-full w-full items-center justify-center px-2 md:mx-0 md:px-0 lg:mb-10 lg:items-center lg:justify-start">
          {/* Sign in section */}
          <form onSubmit={submit} className="mt-[10vh] w-full max-w-full flex-col items-center md:pl-4 lg:pl-0 xl:max-w-[420px]">
            <div className="w-1/2 mb-8 mx-auto">
              <img src={logo} alt="Peak Auto Auctions" className="w-full" />
            </div>

            {error ? (
                <div className="mb-4 font-medium text-sm text-red-600">
                  {error}
                </div>
            ) : recentlyResetPassword && (
                <div className="mb-4 font-medium text-sm text-green-600">
                  Your password has been reset successfully.
                </div>
            )}

            {/* Email */}
            <InputField
              variant="auth"
              extra="mb-3"
              label="Email*"
              placeholder=""
              id="email"
              name="email"
              type="email"
              onChange={handleChange}
              autoComplete="username"
              state={error ? 'error' : ''}
            />

            {/* Password */}
            <InputField
              variant="auth"
              extra="mb-3"
              label="Password*"
              placeholder="Min. 8 characters"
              id="password"
              name="password"
              type="password"
              onChange={handleChange}
              state={error ? 'error' : ''}
              autoComplete="current-password"
            />
            {/* Checkbox */}
            <div className="my-4 flex flex-col gap-4 2xs:flex-row items-center justify-between px-2">
              <div className="flex items-center">
                <Checkbox
                    id="remember"
                    name="remember"
                    onChange={handleChange}
                />
                <label htmlFor="remember" className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                  Keep me logged in
                </label>
              </div>
              <a
                className="text-sm font-medium text-brand-500 hover:text-brand-600 dark:text-white"
                href="/auth/forgot-password"
              >
                Forgot Password?
              </a>
            </div>
            <button className="w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200">
              Sign In
            </button>
          </form>
        </div>
      }
    />
  );
}

export default SignInDefault;
