import axios from 'axios'

export const index = (params, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/portals', {
        params
    }).then(then)
}

export const get = (id, then, params = {}) => {
    axios.get(process.env.REACT_APP_API_URL + '/portal/' + id, {
        params
    }).then(then)
}

export const create = (data, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/portals/create', data)
        .then(then)
}

export const update = (id, data, then) => {
    axios.post(process.env.REACT_APP_API_URL + '/portal/' + id + '/update', data)
        .then(then)
}

export const deletePortal = (id, then) => {
    // Matt asked for this to be impossible to do via the admin
    // axios.delete(process.env.REACT_APP_API_URL + '/portal/' + id + '/delete')
    //     .then(then)
}

export const logos = (id, params, then) => {
    axios.get(process.env.REACT_APP_API_URL + '/portal/' + id + '/logos', {
        params
    }).then(then)
}