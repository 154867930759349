import { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { Dialog } from 'primereact/dialog'
import Button from 'components/buttons/Button'
import {useNotificationsDispatch} from 'components/notifications/NotificationsContext'
import axios from 'axios'
import { XCircleIcon } from '@heroicons/react/24/solid'
import UploadLimits from 'components/uploads/UploadLimits'

const BulkUploadPhotosModal = ({ auction, setLots }) => {
    const [ files, setFiles ] = useState([])
    const [ visible, setVisible ] = useState(false)
    const [ skipDuplicates, setSkipDuplicates ] = useState(true)
    const [ progress, setProgress ] = useState({
        loaded: 0,
        total: 0,
        pct: 0
    })
    const notify = useNotificationsDispatch()

    const header = (
        <div className="text-lg font-bold">
            Bulk Upload Lot Photos
        </div>
    )

    const close = () => {
        setFiles([])
        setVisible(false)
    }

    const footer = (
        <div>
            <Button color="outline" onClick={close}>
                {files.length > 0 ? 'Done' : 'Cancel'}
            </Button>
        </div>
    )

    const onDrop = useCallback(acceptedFiles => {
        const formData = new FormData()

        formData.set('skip_duplicates', skipDuplicates ? '1' : '0')

        acceptedFiles.forEach((file) => {
            formData.append("files[]", file)
        })

        axios.post(process.env.REACT_APP_API_URL + `/auction/${auction.id}/photos/upload`, formData, {
            onUploadProgress: e => {
                setProgress({
                    loaded: e.loaded,
                    total: e.total,
                    pct: +((e.loaded / e.total) * 100).toFixed(2)
                })
            }
        })
            .then((response) => {
                setLots(response.data.lots)
                setFiles(response.data.uploads)
                setProgress({
                    loaded: 0,
                    total: 0,
                    pct: 0
                })

                notify({
                    message: 'Photos have been uploaded.',
                    title: 'Success!',
                    type: 'success',
                })
            })
    }, [skipDuplicates])

    const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
        onDrop,
        noClick: true
    })

    const remove = (file) => {
        setFiles(
            files.filter(f => f !== file)
        )
    }

    const clear = () => {
        setFiles([])
    }

    return (
        <div>
            <Dialog modal draggable={false} resizable={false} visible={visible}
                    header={header} footer={footer} style={{ width: "40rem" }}
                    onHide={close}>
                <p>
                    Import images by filename, where the image files are named with the <b>Lot Number </b>
                    and the sort order in the format 1-1.jpg (png and other image types also accepted).
                </p>

                <label className="flex gap-2 items-center py-4">
                    <input
                        type="checkbox"
                        checked={skipDuplicates}
                        onChange={(e) => setSkipDuplicates(e.target.checked)}
                    />
                    <span>
                        Skip duplicate photos during upload
                    </span>
                </label>

                <div {...getRootProps()} className="bg-gray-200 rounded-lg px-4 pb-4 mt-2">
                    <div onClick={open}>
                        <input {...getInputProps()} />

                        <p className="text-center py-4">
                            { isDragActive ? (
                                <>
                                    Drop the files here...
                                </>
                            ) : (
                                <>
                                    Drag &amp; drop your files or <span className="underline cursor-pointer">Browse</span>
                                </>
                            )}
                        </p>

                        <UploadLimits />
                    </div>

                    {files.length > 0 && (
                        <div className="flex justify-end">
                            <Button size="sm" color="black" onClick={clear}>
                                Clear
                            </Button>
                        </div>
                    )}

                    {progress.total > 0 && (
                        <div className="text-center py-4">
                            Upload progress: {progress.pct}%
                        </div>
                    )}

                    <div className="grid grid-cols-4 gap-4">
                        {files.map((file, i) => (
                            <div key={i} className="relative">
                                <div className="absolute cursor-pointer flex w-full justify-end">
                                    <div onClick={() => remove(file)} className="p-2">
                                        <XCircleIcon className="w-5 h-5 text-black" />
                                    </div>
                                </div>

                                {file.name}:
                                <br />{file.status}
                            </div>
                        ))}
                    </div>
                </div>
            </Dialog>

            <Button size="md" onClick={() => setVisible(true)}>
                Bulk Upload
            </Button>
        </div>
    )
}

export default BulkUploadPhotosModal