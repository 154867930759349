// Custom components
import React, { useEffect, useState } from 'react';
import Label from './Label';

const InputField = React.forwardRef((props, ref) => {
  const {
    label,
    id,
    name,
    value,
    defaultValue,
    extra = '',
    type,
    placeholder,
    variant,
    state,
    disabled,
    onChange,
    above,
    below,
    autoComplete,
  } = props;

  const [cursor, setCursor] = useState(null);

  useEffect(() => {
    const input = ref?.current;

    if (input && type === 'text') {
      input.setSelectionRange(cursor, cursor);
    }
  }, [ref, cursor, value]);

  const handleChange = (e) => {
    setCursor(e.target.selectionStart);
    onChange && onChange(e);
  };

  return (
    <div className={`${extra}`}>
      {label && (
        <div className="flex gap-2">
          <Label htmlFor={id} variant={variant} state={state}>
            {label}
          </Label>
          {above}
        </div>
      )}
      <input
        ref={ref}
        disabled={disabled}
        type={type}
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        defaultValue={defaultValue}
        onChange={handleChange}
        autoComplete={autoComplete}
        required={props.required}
        minLength={props.minLength}
        maxLength={props.maxLength}
        className={`${
          label ? 'mt-2' : ''
        } flex h-12 w-full items-center justify-center rounded-xl border bg-white/0 p-3 text-sm outline-none ${
          disabled === true
            ? 'bg-gray-100 dark:!bg-slate-400 dark:placeholder:!text-[rgba(255,255,255,0.15)]'
            : state === 'error'
            ? 'border-red-500 text-red-500 placeholder:text-red-500 focus:ring-red-500 dark:!border-red-400 dark:!text-red-400 dark:placeholder:!text-red-400'
            : state === 'success'
            ? 'border-green-500 text-green-500 placeholder:text-green-500 dark:!border-green-400 dark:!text-green-400 dark:placeholder:!text-green-400'
            : 'border-gray-200 dark:!border-white/10 dark:text-white'
        }`}
      />
      {below && <div>{below}</div>}
    </div>
  );
});

export default InputField;
