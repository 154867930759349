import Card from 'components/card';
import InputField from 'components/fields/InputField';
import SelectField from 'components/fields/SelectField';
import Button from 'components/buttons/Button';
import { states } from 'services/states';
import { all as getCompanies } from 'services/companies';
import { getPrimaryRoles, getRoles } from 'services/users';
import {displayDatetime, formatDate} from 'helpers/dates';
import { useEffect, useRef, useState } from 'react';
import AddressAutocomplete from 'components/fields/AddressAutocomplete';
import Association from './Association';
import CheckboxField from '../../../../components/fields/CheckboxField'

const Information = ({ user, setUser, isProfile, submit }) => {
  const [roles, setRoles] = useState([]);
  const [primaryRoles, setPrimaryRoles] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [errors, setErrors] = useState({});

  const form = useRef();
  const password = useRef();
  const confirmPassword = useRef();

  useEffect(() => {
    getPrimaryRoles({}, ({ data }) => {
      setPrimaryRoles(data.roles);
    });

    getRoles({}, ({ data }) => {
      setRoles(data.roles);
    });

    getCompanies({}, ({ data }) => {
      setCompanies(data.companies);
    });
  }, []);

  const setData = (k, v) => {
    setUser({
      ...user,
      [k]: v,
    });

    if (k === 'password_confirmation') {
      if (password.current.value !== confirmPassword.current.value) {
        confirmPassword.current.setCustomValidity(
          "Confirm password field doesn't match."
        );
      } else {
        confirmPassword.current.setCustomValidity('');
      }
    }
  };

  const validateAndSubmit = (e) => {
    e.preventDefault();

    if (form.current.reportValidity()) {
      submit(
        () => {
          setErrors({});
        },
        ({ data }) => {
          if (data.errors) {
            setErrors(data.errors);
          }
        }
      );
    }
  };

  const association = user?.associations?.length > 0 ? user.associations[0] : {
    id: 'new',
    associated_type: 'App\\Models\\Company',
    associated_id: companies.length > 0 ? companies[0].id : null,
    role_id: null
  };

  const setAssociations = (associations) => {
    setUser({
      ...user,
      associations
    })
  }

  const setAssociation = (editedAssociation) => {
    setAssociations(
        user.associations.length > 0
            ? user.associations.map(association => association.id === editedAssociation.id ? editedAssociation : association)
            : [editedAssociation]
    )
  }

  return (
    <Card extra={'w-full !p-5'}>
      {/* Header */}
      <div className="w-full px-[8px]">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Account Information
        </h4>
        <p className="mt-1 text-base text-gray-600">
          Here you can enter the user's account information
        </p>
      </div>

      <form ref={form} className="mb-2 mt-7 grid grid-cols-2 gap-3">
        <InputField
          label="First Name"
          id="first_name"
          type="text"
          value={user.first_name || ''}
          onChange={(e) => setData('first_name', e.target.value)}
          required
        />
        <InputField
          label="Last Name"
          id="last_name"
          type="text"
          value={user.last_name || ''}
          onChange={(e) => setData('last_name', e.target.value)}
          required
        />
        <InputField
          label="Email Address"
          id="user_email"
          type="email"
          value={user.email || ''}
          autoComplete="off"
          onChange={(e) => setData('email', e.target.value)}
          state={errors.email ? 'error' : ''}
          above={
            errors.email ? (
              <p className="mt-0.5 text-xs text-red-500">{errors.email[0]}</p>
            ) : (
              ''
            )
          }
          required
        />
        <InputField
          label="Username"
          placeholder="Optional"
          id="user_username"
          type="text"
          value={user.username || ''}
          autoComplete="disable-autocomplete"
          onChange={(e) => setData('username', e.target.value)}
          state={errors.username ? 'error' : ''}
          above={
            errors.username ? (
              <p className="mt-0.5 text-xs text-red-500">
                {errors.username[0]}
              </p>
            ) : (
              ''
            )
          }
        />
        <InputField
          label="Phone Number"
          id="phone"
          type="tel"
          value={user.phone || ''}
          onChange={(e) => setData('phone', e.target.value)}
        />

        <AddressAutocomplete formData={user} setFormData={setUser} />

        <InputField
          label="City"
          id="city"
          type="text"
          value={user.city || ''}
          onChange={(e) => setData('city', e.target.value)}
        />
        <SelectField
          label="State"
          id="state"
          value={user.state || ''}
          onChange={(e) => setData('state', e.target.value)}
          options={states}
        />
        <InputField
          label="ZIP Code"
          id="zip"
          type="text"
          value={user.zip || ''}
          onChange={(e) => setData('zip', e.target.value)}
        />

        <InputField
          label="Date of Birth"
          id="dob"
          type="date"
          value={user.dob ? formatDate(user.dob) : ''}
          onChange={(e) => setData('dob', e.target.value)}
        />

        <InputField
          label="Driver's License"
          id="drivers_license"
          type="text"
          value={user.drivers_license || ''}
          onChange={(e) => setData('drivers_license', e.target.value)}
        />

        <InputField
          label="License Number (if applicable)"
          id="license_number"
          type="text"
          value={user.license_number || ''}
          onChange={(e) => setData('license_number', e.target.value)}
        />

        {!isProfile && (
          <>
            <SelectField
              label="Status"
              id="status"
              value={user.status || ''}
              onChange={(e) => setData('status', e.target.value)}
              options={[
                {
                  label: 'Declined',
                  value: 'declined',
                },
                {
                  label: 'Pending',
                  value: 'pending',
                },
                {
                  label: 'Approved',
                  value: 'approved',
                },
              ]}
            />

            {primaryRoles.length > 0 ? (
                <>
                  <SelectField
                      label="Primary Role"
                      id="role_id"
                      value={user.role_id || ''}
                      onChange={(e) => setData('role_id', e.target.value)}
                      options={primaryRoles.map((role) => {
                        return {
                          label: role.name,
                          value: role.id,
                        };
                      })}
                  />
                  <div>
                    <CheckboxField
                        label="Email Verified"
                        checked={!!user.email_verified_at}
                        onChange={(e) => setData('email_verified_at', e.target.checked ? new Date().toUTCString() : null)}
                    />
                    <div className={`text-sm mt-2 ${user.email_verified_at ? '' : 'invisible'}`}>
                      {user.email_verified_at ? displayDatetime(user.email_verified_at) : 'N/A'}
                    </div>
                  </div>
                </>
            ) : (
                <Association
                    association={association}
                    setAssociation={setAssociation}
                    roles={roles}
                    companies={companies}
                />
            )}
          </>
        )}

        {!user.id && (
          <>
            <InputField
              autoComplete="new-password"
              ref={password}
              label="Password (minimum 8 characters)"
              id="user_password"
              type="password"
              onChange={(e) => setData('password', e.target.value)}
              minLength="8"
              required={true}
            />

            <InputField
              autoComplete="off"
              ref={confirmPassword}
              label="Confirm Password"
              id="password_confirmation"
              type="password"
              onChange={(e) => setData('password_confirmation', e.target.value)}
              required={true}
            />
          </>
        )}
      </form>

      <div className="mt-12 flex w-full justify-end">
        <Button onClick={validateAndSubmit}>Submit</Button>
      </div>
    </Card>
  );
};

export default Information;
