import { useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import React from 'react';
import SelectField from '../fields/SelectField';
import { Link } from 'react-router-dom'

const VerticalMenu = ({ items }) => {
  const navigate = useNavigate();

  const currentItem = items.filter((item) => item.current)[0] || items[0];

  return (
    <>
      <div className="block sm:hidden">
        <SelectField
          value={currentItem.href}
          options={items.map((item) => {
            return {
              value: item.href,
              label: item.name,
            };
          })}
          onChange={(e) => {
            navigate(e.target.value);
          }}
        />
      </div>

      <nav
        className="mr-4 hidden w-36 flex-shrink-0 flex-col sm:relative sm:flex"
        aria-label="Sidebar"
      >
        <ul role="list" className="-mx-2 space-y-1">
          {items.map((item) => (
            <li key={item.name}>
              <Link
                to={item.href}
                className={classNames(
                  item.current
                    ? 'bg-gray-50 text-slate-600 dark:bg-gray-800 dark:text-gray-100'
                    : 'text-gray-700 hover:bg-gray-50 hover:text-slate-600 dark:text-gray-100 dark:hover:bg-gray-800 dark:hover:text-gray-300',
                  'group flex gap-x-3 rounded-md p-2 pl-3 text-sm font-semibold leading-6'
                )}
              >
                {item.name}
                {item.count ? (
                  <span
                    className="ml-auto w-9 min-w-max whitespace-nowrap rounded-full bg-white px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-gray-600 ring-1 ring-inset ring-gray-200"
                    aria-hidden="true"
                  >
                    {item.count}
                  </span>
                ) : null}
              </Link>
            </li>
          ))}
        </ul>
      </nav>
    </>
  );
};

export default VerticalMenu;
