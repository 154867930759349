import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { get } from 'services/users'
import { useDispatch } from 'react-redux'
import { updatePermissions, useAuthDispatch } from 'components/auth/AuthContext'
import { impersonate } from 'store/user'
import UserLayout from './components/UserLayout'
import Link from 'components/buttons/Link'
import {
    FaChartLine,
    FaCircleDollarToSlot,
    FaEye,
    FaFileInvoiceDollar
} from 'react-icons/fa6'
import { AuctionStats } from '../auctions/components/AuctionStats'
import { formatCurrency } from 'helpers/currency'
import { displayDatetime } from 'helpers/dates'
import { can, useAuth } from 'components/auth/AuthContext'
import ActionPanel from 'components/panels/ActionPanel'
import BanUserModal from './components/modals/BanUserModal'
import UnbanUserModal from './components/modals/UnbanUserModal'
import DeleteUserModal from './components/modals/DeleteUserModal'

const Dashboard = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const auth = useAuth()
    const authDispatch = useAuthDispatch()
    const canBan = can(auth, 'ban_users')
    const canImpersonate = can(auth, 'impersonate_users')
    const canDelete = can(auth, 'delete_users')

    const { id } = useParams()
    const [ user, setUser ] = useState(null)
    const [ stats, setStats ] = useState([])
    const [ visibleModal, setVisibleModal ] = useState(null)

    useEffect(() => {
        get(id, ({ data }) => {
            setUser(data.user)
            // console.log(data.user.stats)
        })
    }, [id])

    useEffect(() => {
        if (! user) {
            return
        }

        setStats(
            buildStats()
        )
    }, [user])

    const buildStats = () => {
        return [
            {
                icon: FaChartLine,
                title: 'Bid Count',
                value: user.counts.bids
            },
            {
                icon: FaCircleDollarToSlot,
                title: 'Bid Total',
                value: formatCurrency(user.stats.bid_total, 2) || '$0.00'
            },
            {
                icon: FaEye,
                title: 'Watch Count',
                value: user.counts.watched_lots + ' lots',
                sub: user.counts.watched_auctions + ' auctions'
            },
            {
                icon: FaFileInvoiceDollar,
                title: 'Invoices',
                value: formatCurrency(user.stats.invoice_total, 2) || '$0.00',
                sub: (formatCurrency(user.stats.invoice_balance, 2) || '$0.00') + ' due'
            },
        ]
    }

    const startImpersonation = () => {
        dispatch(impersonate(user, () => {
            updatePermissions(authDispatch, () => {
                navigate('/admin/dashboard')
            })
        }, () => {

        }))
    }

    if (! user) {
        return
    }

    return (
        <UserLayout id={id} user={user}>
            <div>
                <AuctionStats stats={stats} />

                <div className="flex flex-col gap-1 mt-4">
                    {user.email && (
                        <div className="flex gap-1">
                            <span className="font-bold">Email Address:</span>
                            <Link to={'/admin/user/' + user.id + '/edit'}>
                                {user.email}
                            </Link>
                        </div>
                    )}

                    {user.username && (
                        <div className="flex gap-1">
                            <span className="font-bold">Username:</span>
                            <Link to={'/admin/user/' + user.id + '/edit'}>
                                {user.username}
                            </Link>
                        </div>
                    )}

                    <div className="flex gap-1">
                        <span className="font-bold">Auctions Participated In:</span>
                        <span>{user.counts.auctions}</span>
                    </div>

                    <div className="flex gap-1">
                        <span className="font-bold">First Bid Auction:</span>
                        {user.stats.first_auction ? (
                            <a href={`/admin/auction/${user.stats.first_auction.id}`}>
                                {user.stats.first_auction.name}
                            </a>
                        ) : (
                            <span>N/A</span>
                        )}
                    </div>

                    <div className="flex gap-1">
                        <span className="font-bold">Running Total:</span>
                        <span>{formatCurrency(user.stats.running_total, 2) || '$0.00'}</span>
                    </div>

                    <div className="flex gap-1">
                        <span className="font-bold">Registration Date:</span>
                        <span>{displayDatetime(user.created_at) || 'N/A'}</span>
                    </div>

                    <div className="flex gap-1">
                        <span className="font-bold">Email Verification Date:</span>
                        <span>{displayDatetime(user.email_verified_at) || 'N/A'}</span>
                    </div>

                    <div className="flex gap-1">
                        <span className="font-bold">Last Login Date:</span>
                        <span>{displayDatetime(user.last_login_at) || 'N/A'}</span>
                    </div>

                    <div className="flex gap-1">
                        <span className="font-bold">Last Active Date:</span>
                        <span>{displayDatetime(user.last_active_at) || 'N/A'}</span>
                    </div>

                    <div className="flex gap-1">
                        <span className="font-bold">Last Bid Date:</span>
                        <span>{displayDatetime(user.last_bid_at) || 'N/A'}</span>
                    </div>

                    <div className="flex gap-1">
                        <span className="font-bold">Last Login IP:</span>
                        <span>{user.login_ip || 'N/A'}</span>
                    </div>

                    <div className="flex gap-1">
                        <span className="font-bold">Last Active IP:</span>
                        <span>{user.ip || 'N/A'}</span>
                    </div>
                </div>

                {canImpersonate && user.can_be_impersonated && (
                    <ActionPanel
                        color="outline"
                        title="Impersonate User"
                        action="Impersonate"
                        onClick={() => startImpersonation()}
                    >
                        Temporarily log in as this user
                    </ActionPanel>
                )}

                {canBan && (
                    user.suspended_at ? (
                        <ActionPanel
                            color="amber"
                            title="Unban User"
                            action="Unban"
                            onClick={() => setVisibleModal('unban')}
                        >
                            Unban this user
                        </ActionPanel>
                    ) : (
                        <ActionPanel
                            color="amber"
                            title="Ban User"
                            action="Ban"
                            onClick={() => setVisibleModal('ban')}
                        >
                            Ban this user
                        </ActionPanel>
                    )
                )}

                {canDelete && (
                    <ActionPanel
                        color="red"
                        title="Delete User"
                        action="Delete"
                        onClick={() => setVisibleModal('delete')}
                    >
                        Delete this user and everything associated with it, such as invoices and notes
                    </ActionPanel>
                )}
            </div>

            <BanUserModal
                user={user}
                visible={visibleModal}
                setVisible={setVisibleModal}
                then={(user) => {
                    setUser(user)
                }}
            />

            <UnbanUserModal
                user={user}
                visible={visibleModal}
                setVisible={setVisibleModal}
                then={(user) => {
                    setUser(user)
                }}
            />

            <DeleteUserModal
                user={user}
                visible={visibleModal}
                setVisible={setVisibleModal}
                then={() => {
                    navigate('/admin/users')
                }}
            />
        </UserLayout>
    )
}

export default Dashboard