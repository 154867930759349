import { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import PortalLayout from './components/PortalLayout'
import { get, update } from 'services/portals'
import Card from 'components/card'
import Button from 'components/buttons/Button'
import ReactQuill from 'react-quill'

const PortalTerms = () => {
    const navigate = useNavigate()
    const { id } = useParams()
    const [ portal, setPortal ] = useState({})
    const [ terms, setTerms ] = useState({})

    useEffect(() => {
        if (id) {
            get(id, ({ data }) => {
                setPortal(data.portal)
                setTerms(data.terms)
            }, {
                terms: true
            })
        }
    }, [])

    const updateTerms = (k, v) => {
        setTerms({
            ...terms,
            [k]: v
        })
    }

    const submit = (e) => {
        e.preventDefault()

        portal.terms = terms

        update(id, portal, ({ data }) => {
            navigate('/admin/portal/' + id)
        }, () => {

        })
    }

    return (
        <PortalLayout id={id} portal={portal}>
            <div className="mt-3 grid h-full w-full grid-cols-1 gap-5 rounded-[20px] lg:grid-cols-2">
                <form className="flex flex-col gap-5" onSubmit={submit}>
                    <Card extra={"w-full !p-5"}>
                        {/* Header */}
                        <div className="w-full px-[8px]">
                            <h4 className="text-xl font-bold text-navy-700 dark:text-white">
                                Portal Terms
                            </h4>

                            <div className="mt-8">
                                <h5 className="font-bold">Privacy Policy</h5>

                                <ReactQuill theme="snow"
                                            className="mt-2"
                                            value={terms.privacy}
                                            onChange={(v) => updateTerms('privacy', v)}
                                />
                            </div>

                            <div className="mt-8">
                                <h5 className="font-bold">Terms Of Service</h5>
                                <ReactQuill theme="snow"
                                            className="mt-2"
                                            value={terms.tos}
                                            onChange={(v) => updateTerms('tos', v)}
                                />
                            </div>
                        </div>
                    </Card>

                    <div className="mt-12 flex w-full justify-end">
                        <Button>Submit</Button>
                    </div>
                </form>
            </div>
        </PortalLayout>
    )
}

export default PortalTerms